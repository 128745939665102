import state from "./moduleNationalitiesState.js";
import mutations from "./moduleNationalitiesMutations.js";
import actions from "./moduleNationalitiesActions.js";
import getters from "./moduleNationalitiesGetters.js";

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
};
