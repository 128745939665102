/*=========================================================================================
  File Name: moduleCourseClassActions.js
  Description: CourseClass Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
import axios from "@/axios.js";
export default {
  AddNewNationality({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/Nationalities/AddNewNationality", item)
        .then(response => {
          commit("ADD_Nationalities", Object.assign(item, { ID: response.data.ID }));
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  GetAllNationalities({ commit }) {
    debugger;
    return new Promise((resolve, reject) => {
      axios
        .get("api/Nationalities/GetAllNationalities")
        .then(response => {
          if (response.status == 200) {
            commit("SET_Nationalities", response.data);
            resolve(response);
          }

        })
        .catch((error) => { reject(error) })
    })
  },

  GetNationalityById(context, itemid) {
    return new Promise((resolve, reject) => {
      axios.get("api/Nationalities/GetNationalityById?ID="+ itemid)
        .then((response) => {
          debugger

          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },


  UpdateNationality({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios
        .put("api/Nationalities/UpdateNationality" , item)
        .then(response => {
          debugger
          commit("UPDATE_Nationalities", item);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  DeleteNationality({ commit }, item) {
      debugger;
    return new Promise((resolve, reject) => {
      axios
        .post("api/Nationalities/DeleteNationality" , item)
        .then(response => {
          resolve(response);
          commit("REMOVE_Nationalities", item);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

};
