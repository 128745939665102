export default {

  ADD_Nationalities(state, item) {
    state.Nationalities.unshift(item);
  },

  SET_Nationalities(state, Nationalities) {
    state.Nationalities = Nationalities;
  },

  UPDATE_Nationalities(state, item) {
    debugger;
    const Index = state.Nationalities.findIndex(p => p.Id == item.ID);
    Object.assign(state.Nationalities[Index], item);
  },

  REMOVE_Nationalities(state, itemId) {
    const ItemIndex = state.Nationalities.findIndex(p => p.Id == itemId);
    state.Nationalities.splice(ItemIndex, 1);
  }

};
